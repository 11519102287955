// import logo from './logo.svg';
// import { useDispatch, useSelector } from 'react-redux';
import { useContext } from 'react';
import { Routes, Route, HashRouter, BrowserRouter } from "react-router-dom"
import { ToastContainer } from 'react-toastify';

import './App.scss';
import Login from './Pages/Login/Login';
import SignUp from './Pages/SignUp/SignUp';
import PublicLayout from './Layout/PublicLayout/PublicLayout';
import PrivateLayout from './Layout/PrivateLayout/PrivateLayout';


import CustomCookieConsent from './Component/CookieConsent/CookieConsent';

import Home from './Pages/Home/Home';
// import Battle from './Pages/Battle/Battle';
// import OpenBattle from './Pages/OpenBattle/OpenBattle';
import ForgotPassword from './Pages/ForgotPassword/ForgotPassword';
// import ActiveBattle from './Pages/BattlePage/ActiveBattlePage/ActiveBattlePage';
import BattleDetail from './Pages/BattleDetail/BattleDetail';
import Search from './Pages/Search/Search';
import UserProfile from './Pages/UserProfile/UserProfile';
import BattlePage from './Pages/BattlePage/BattlePage';
import EmailLinkForPassword from './Pages/EmailLinkForPassword/EmailLinkForPassword';
import UpdateProfile from './Pages/UpdateProfile/UpdateProfile';
import { SocketContext } from './Context/socket';

import TrackPage from './Pages/TrackPage/TrackPage';

import Legal from './Pages/Legal/Legal';
import FAQ from './Pages/FAQ/FAQ';
// import Player from './Component/Player/Player';
import WrapLayout from './Layout/WrapLayout/WrapLayout';
import EditProfile from './Pages/EditProfile/EditProfile';
import ActiveBattlePage from './Pages/ActiveBattlePage/ActiveBattlePage';
import ViewAll from './Component/ViewAll/ViewAll';
import Genre from './Pages/Genre/Genre';
// import { updateSpaceBarRead } from './Redux/Slices/player.slice';
import PageNotFound from './Pages/PageNotFound/PageNotFound';
import ContactUs from './Pages/ContactUs/ContactUs';

import { InitializeAnalytics } from './Helper/Analytics';

function App() {
  const socket = useContext(SocketContext);
  // console.log("this is socket", socket)

  InitializeAnalytics();
  // const dispatch = useDispatch()
  // const socketId = socket ? socket.id : "";
  // const { userId } = useSelector(state => state.user);




  // const { showPlayer } = useSelector(state => state.player)
  return (
    <div className="App">
      <CustomCookieConsent />
      <HashRouter>
        {/* <BrowserRouter> */}
        <Routes>
          <Route path='/' element={<WrapLayout />} >

            <Route path='/public' element={<PublicLayout />}>
              <Route index element={<Login />} />
              <Route path='signup' element={<SignUp />} />
              <Route path='emailLinkForPassword' element={<EmailLinkForPassword />} />
              <Route path='forgotpassword' element={<ForgotPassword />} />

            </Route>



            <Route index element={<Home />} />
            <Route path='/:string/:type/:heading' element={<ViewAll />} />
            <Route path='battle-page' element={<BattlePage />} />
            <Route path='battle/:id' element={<BattleDetail />} />
            <Route path='active-battle' element={<ActiveBattlePage />} />
            <Route path='track/:id' element={<TrackPage />} />
            <Route path='search' element={<Search />} />
            <Route path='profile/:id' element={<UserProfile />} />
            <Route path='genre/:genre' element={<Genre />} />
            <Route path='legal' element={<Legal />} />
            <Route path='faq' element={<FAQ />} />
            <Route path='contact' element={<ContactUs />} />

            <Route path='/private' element={<PrivateLayout />}>
              {/* <Route path='active-battle' element={<ActiveBattle />} /> */}

              <Route path='profile/:id' element={<UserProfile />} />

              <Route path='update-profile' element={<UpdateProfile />} />
              <Route path='edit-profile' element={<EditProfile />} />

            </Route>
            <Route path='page-not-found' element={<PageNotFound />} />
            <Route path='*' element={<PageNotFound />} />
          </Route>


        </Routes>
        <ToastContainer />
        {/* </BrowserRouter> */}
      </HashRouter>
    </div>
  );
}

export default App;