import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import { Modal, Button } from 'react-bootstrap';
import { yupResolver } from '@hookform/resolvers/yup';
import { setUpAccountSchema } from '../../ValidationSchema/validationSchema';
import * as cs from '../../Api/cs'
import useHeader from '../../Helper/useHeader';
import { DeleteIcon, ImageIcon } from '../../Component/Icons';
import { useDropzone } from 'react-dropzone';
// import UserImg from '../../assets/images/thumbnail.png'
import { BallTriangle } from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserData } from '../../Redux/Slices/user.slice';

const SetUpAccount = ({ showSetUp, handleSetUpClose }) => {

    const { userInfo } = useSelector(state => state.user)


    const headers = useHeader()
    const [image, setImage] = useState(null)
    const [auto, setAuto] = useState(false)
    const [loading, setLoading] = useState(false);
    const [alreadyExist, setAlreadyExist] = useState(false)
    const [suggestion, setSuggestion] = useState([])
    const dispatch = useDispatch()

    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors },
        register,
        watch,
    } = useForm({
        resolver: yupResolver(setUpAccountSchema),
        mode: 'onChange',
    });


    useEffect(() => {
        if (userInfo !== null) {
            setImage(userInfo?.image)
            setValue("username", userInfo?.userName)
            setValue('name', userInfo?.name)
        }
    }, [])


    const onSubmit = async (data) => {
        // Handle the form submission logic here

        setLoading(true);
        const upload_data = {}
        let image

        if (data?.profilePicture && data?.profilePicture[0]) {
            const imgFormObj = new FormData()
            imgFormObj.append("data", data?.profilePicture[0])

            image = await cs.post('upload', 'util', imgFormObj, headers)

        }



        upload_data.name = data?.name || data?.username;
        upload_data.userName = data?.username
        upload_data.contactNumber = data?.contactNumber
        upload_data.image = image?.data?.data ? image?.data?.data : userInfo?.image

        const response = await cs.post('setUpProfile', 'users', upload_data, headers)
        if (response?.data?.verified) {
            setLoading(false)
            handleSetUpClose()
            // getUserData()
            dispatch(updateUserData(response?.data?.data))
        }
        else if (!response?.data?.verified) {
            setLoading(false)
            // setAlreadyExist(true)
            setSuggestion(response?.data?.data)
        }
    };

    // const handleSkip = async () => {
    //     setLoading(true);
    //     setAuto(true)
    //     const _data = {
    //         userName: null
    //     }
    //     const response = await cs.post('updateProfile', 'users', _data, headers)
    //     if (response?.data?.code === 200) {

    //         setTimeout(() => {
    //             setLoading(false);
    //             handleSetUpClose()
    //         }, 3000);
    //     }

    // }

    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*', // Specify accepted file types
        onDrop: (acceptedFiles) => {
            // Handle dropped files
            setValue('profilePicture', acceptedFiles);
            setImage(URL.createObjectURL(acceptedFiles[0]));
        }
    });


    return (
        <Modal show={showSetUp}>
            <Modal.Header >
                <Modal.Title>Set Up Your Account </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                {loading && ( // Show loader if loading state is true
                    <div className="loader-container">
                        <div className="loader">
                            <BallTriangle
                                type="TailSpin"
                                color="#00BFFF"
                                height={50}
                                width={50}
                            />
                        </div>
                    </div>
                )}
                <div className='auth-form-wrapper-main'>
                    <form className="auth-form-wrapper" onSubmit={handleSubmit(onSubmit)}>
                        <div className='auth-form'>
                            {alreadyExist && <div style={{ color: "red" }}>Username Already Exist!!! </div>}
                            <div className="form-row">
                                {/* Profile Image Field */}
                                <Form.Group controlId="profilePicture">
                                    <div className='file-upload-wrap'>

                                        {!image &&

                                            <label htmlFor='profilePicture' className='file-upload' {...getRootProps()}>
                                                <ImageIcon />
                                                <div className='profile-text-wrap'>
                                                    Select profile image <br /> or drop it here
                                                </div>
                                                <input {...getInputProps()} />
                                            </label>
                                        }

                                        {/* Condition needs to apply for Image upload */}

                                        {image &&
                                            <div className='image-upload-holder'>
                                                <img src={image} alt='user' />
                                                <div className='delete-img-wrap' onClick={() => setImage(null)}>
                                                    <DeleteIcon />
                                                </div>
                                            </div>
                                        }

                                    </div>
                                    {errors.profilePicture && (
                                        <div className="errorMsg">{errors.profilePicture.message}</div>
                                    )}
                                </Form.Group>
                            </div>

                            <div className="form-row">
                                {/* Username Field */}
                                <Form.Group controlId="username">

                                    <Form.Control
                                        type="text"
                                        placeholder="Username *"
                                        {...register('username', { required: 'Username is required' })}
                                    />
                                    {errors.username && (
                                        <div className="errorMsg">{errors.username.message}</div>
                                    )}
                                </Form.Group>
                            </div>

                            <div className="form-row">
                                {/* Name Field */}
                                <Form.Group controlId="name">

                                    <Form.Control
                                        type="text"
                                        placeholder="Artist Name"
                                        {...register('name', { required: 'Artist Name is required' })}
                                    />
                                    {errors.name && (
                                        <div className="errorMsg">{errors.name.message}</div>
                                    )}
                                </Form.Group>
                            </div>


                            {alreadyExist && suggestion?.length > 0 && (
                                <div className="form-row">
                                    <h5 style={{ color: "white" }}>Suggested Username :</h5>
                                    {suggestion.map((suggest, index) => (
                                        <div key={index}>
                                            <input
                                                type="radio"
                                                id={`suggest${index}`}
                                                value={suggest}
                                                name="suggestion"
                                                onChange={(e) => setValue('username', e.target.value)}
                                            />
                                            <label htmlFor={`suggest${index}`} style={{ color: "white" }}>{suggest}</label>
                                        </div>
                                    ))}
                                </div>
                            )}

                            <div className="setup-btn-wrap">
                                <Button variant="primary" type="submit">
                                    Done
                                </Button>

                            </div>


                        </div>
                    </form>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default SetUpAccount
