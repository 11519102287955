import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'
import Player from '../../Component/Player/Player'


import { AnalyticsSetUser } from '../../Helper/Analytics'

const WrapLayout = () => {
    const { showPlayer } = useSelector(state => state.player)
    const { userId, email } = useSelector(state => state.user)
    // const { userId } = useSelector(state => state.user)

    useEffect(() => {
        if (userId) {
            AnalyticsSetUser({
                userId
            });
        }
    }, [userId])


    return (
        <>
            <Outlet />
            {showPlayer && <Player />}

        </>
    )

}

export default WrapLayout


