import React, { useEffect, useRef, useState } from 'react';
import Logo from '../../assets/images/beta_logo.png'
import './Sidebar.scss'
import { Scrollbars } from 'react-custom-scrollbars-2';
import { AddIcon, BattleIcon, HomeIcon, LibraryIcon, SearchIcon, QuestionCircleIcon } from '../Icons';
import { useLocation, useNavigate } from 'react-router-dom';
import * as cs from '../../Api/cs'
import { useDispatch, useSelector } from 'react-redux';
import { updateActiveMenu } from '../../Redux/Slices/sidebar.slice';
import StartBattleModal from '../../Modal/StartBattleModal/StartBattleModal';
import useHeader from '../../Helper/useHeader';
import OpenBattleModal from '../../Modal/OpenBattleModal/OpenBattleModal';
import AddNewTrackModal from '../../Modal/AddNewTrackModal/AddNewTrackModal'
import CustomTextModal from '../../Modal/CustomTextModal';
import { updateBlinkSearch } from '../../Redux/Slices/search.slice';
import Login from '../../Pages/Login/Login';
import SignUp from '../../Pages/SignUp/SignUp';
import OtpModal from '../../Modal/OtpModal';
import EmailLinkForPassword from '../../Pages/EmailLinkForPassword/EmailLinkForPassword';
import SetUpAccount from '../../Modal/SetUpAccount/SetUpAccount';

const Sidebar = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [showModal, setShowModal] = useState(false)
  const [trackInfo, setTrackInfo] = useState('');
  const [battleOption, setBattleOption] = useState('')
  const [addToPlaylist, setAddToPlaylist] = useState(false);
  const [sort, setSort] = useState('dsc')
  const [filter, setFilter] = useState('abcd')
  const [battleList, setBattleList] = useState([])
  // const [myTrack, setMyTrack] = useState([])
  const [showOpenBattleModal, setShowOpenBattleModal] = useState(false);
  const [customModal, setCustomModal] = useState(false)
  const [modalTitle, setModalTitle] = useState()
  const [modalBody, setModalBody] = useState()
  const [showLogin, setShowLogin] = useState(false);
  const [showOtpModal, setShowOtpModel] = useState(false)
  const [showAddTrack, setShowAddTrack] = useState(false)
  const [emailLinkModal, setEmailLinkModal] = useState(false)
  const [showSignUp, setShowSignUp] = useState(false)
  const [showSetUp, setShowSetUp] = useState(false)
  const [user, setUser] = useState(null)
  const [UserId, setUserId] = useState(null)
  const [token2, setToken2] = useState(null)



  const location = useLocation();


  const scrollbarsRef = useRef(null);

  const scrollToBottom = () => {

    const { scrollHeight, clientHeight } = scrollbarsRef.current.getValues();
    scrollbarsRef.current.scrollTop(scrollHeight - clientHeight);
  };


  const handleLoginClose = () => {
    setShowLogin(false)
  }

  useEffect(() => {
    if (location.pathname === "/private/profile")
      scrollToBottom()
  }, [location])

  useEffect(() => {
    if (location.search.includes('startBattle=true') && userId) {
      setShowModal(true);
    }
  }, [location.search]);


  useEffect(() => {
    if (location.search.includes('uploadTrack=true') && userId) {
      setShowAddTrack(true);
    }
  }, [location.search]);


  const handleAddTrackClose = () => {
    setShowAddTrack(false)
  }

  const handleOtpClose = () => {
    setShowOtpModel(false)
  }

  const handleSignUpClose = () => {
    setShowSignUp(false)
  }
  const handleSetUpClose = () => {
    setShowSetUp(false)
  }

  const handleEmailLinkClose = () => {
    setEmailLinkModal(false)
  }

  const headers = useHeader()

  const { userId } = useSelector(state => state.user)

  const { activeMenu, activeSideBarMenu } = useSelector(state => state.sidebar)



  const handleMenuItemClick = (menuName) => {

    dispatch(updateActiveMenu(menuName))

  };

  useEffect(() => {
    getBattleList()
  }, [])


  const getBattleList = async () => {
    const response = await cs.get('list/' + sort + "/" + filter, 'battel', {}, headers)
    setBattleList(response?.data?.data)

  }

  const getMyTrack = async () => {
    // const response = await cs.get('getById/' + userId, "track", {}, headers)
    // setMyTrack(response?.data?.data)
  }


  const handleOpenBattleClose = () => setShowOpenBattleModal(false);

  const handleClose = () => setShowModal(false);

  const handleAddIcon = () => {
    if (userId == null || userId == undefined) {
      // setModalTitle("Can't Create Battle!!!")
      // setModalBody(`Please Login First`)
      // setCustomModal(true)
      setShowLogin(true)
    }
    else if (userId != null) {

      setShowModal(true)
    }

  }

  const handleCustomClose = () => {
    setCustomModal(false)
  }

  const handleMyBattle = () => {
    if (userId == null) {
      setShowLogin(true)
    }
    else if (userId != null) {
      handleMenuItemClick('battle');
      navigate(`/private/profile/${userId}`, { state: { type: "user", id: userId, heading: "Battles", button: 'Active battles', activeTab: 'Active battles' } })
    }
  }


  const handleBattleHistory = () => {
    if (userId == null) {
      setShowLogin(true)
    }
    else if (userId != null) {
      handleMenuItemClick('battle');
      navigate(`/private/profile/${userId}`, { state: { type: "user", id: userId, heading: "Battles", button: 'Battle history', activeTab: 'Battle history' } })
    }
  }


  const handleMyTrack = () => {
    if (userId == null) {
      setShowLogin(true)
    }
    else if (userId != null) {
      handleMenuItemClick('library');
      navigate(`/private/profile/${userId}`, { state: { type: "user", id: userId, heading: "Library", activeTab: 'My Track' } })
    }
  }


  const handleLikedTrack = () => {
    if (userId == null) {
      setShowLogin(true)
    }
    else if (userId != null) {
      handleMenuItemClick('library');
      navigate(`/private/profile/${userId}`, { state: { type: "user", id: userId, heading: "Library", activeTab: 'Liked Tracks' } })
    }
  }



  const handleFAQ = () => {
    navigate(`/faq`);
  }


  return (
    <div className="sidebar">
      <div className='sidebar-logo' onClick={() => { handleMenuItemClick('home'); navigate('/') }}>

        <img src={Logo} alt='CNTNDR' />
      </div>
      <div className='sidebar-menu-wrapper'>
        <Scrollbars style={{ width: '100%', height: '100%' }} ref={scrollbarsRef} disableHorizontalScrolling>
          <ul className="sidebar-menu">

            <li onClick={() => { handleMenuItemClick('home'); navigate('/') }}>
              <div className='nav-item-wrap'>
                <div className={`nav-wrap home ${activeMenu === 'home' ? 'active' : ''}`}>
                  <div className='sidebar-nav-icon'><HomeIcon /></div>
                  <span>Home</span>
                </div>
              </div>
            </li>


            <li onClick={() => { handleMenuItemClick('search'); dispatch(updateBlinkSearch(true)) }}>
              <div className='nav-item-wrap'>
                <div className={`nav-wrap  ${activeMenu === 'search' ? 'active' : ''}`}>
                  <div className='sidebar-nav-icon'><SearchIcon /></div>
                  <span> Search</span>
                </div>
              </div>
            </li>
            <li>
              <div className='nav-item-wrap'>
                <div className={`nav-wrap  ${activeMenu === 'battle' ? 'active' : ''}`}>
                  <div className='sidebar-nav-icon'><BattleIcon /></div>
                  <span>Battles</span>
                </div>
                <div className='submenu-wrap-main'>
                  <ul className='sub-menu'>
                    <li style={{ color: location?.pathname === "/active-battle" && "white" }} className="subMenu_Active" onClick={() => { handleMenuItemClick('battle'); navigate('/active-battle') }}>All Battles</li>
                    <li style={{ color: (activeSideBarMenu === "Active battles" && location?.pathname === "/private/profile") && "white" }} onClick={handleMyBattle}>My Battles</li>
                    <li style={{ color: (activeSideBarMenu === "Battle history" && location?.pathname === "/private/profile") && "white" }} onClick={handleBattleHistory}>Battle History</li>
                  </ul>
                </div>
              </div>
            </li>
            {/* <li>
              <div className='nav-item-wrap'>
                <div className='nav-wrap'>
                  <div className='sidebar-nav-icon'><ShoppingBagIcon /></div>
                  <span>Shop</span>
                </div>
              </div>
            </li> */}
            <li>
              <div className='nav-item-wrap'>
                <div className={`nav-wrap  ${activeMenu === 'library' ? 'active' : ''}`}>
                  <div className='sidebar-nav-icon'><LibraryIcon /></div>
                  <span>Library</span>
                </div>
                <div className='submenu-wrap-main'>
                  <ul className='sub-menu'>
                    <li style={{ color: (activeSideBarMenu === "My Track" && location?.pathname === "/private/profile") && "white" }} onClick={handleMyTrack}>My Tracks</li>
                    <li style={{ color: activeSideBarMenu === "Liked Tracks" && "white" }} onClick={handleLikedTrack}>Liked Tracks</li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </Scrollbars>
        <ul className="sidebar-menu bottom">
          <li>
            <div className='nav-item-wrap' onClick={handleFAQ}>
              <div className='nav-wrap'>
                <div className='sidebar-nav-icon'>
                  {/* <DiscordIcon /> */}
                  <QuestionCircleIcon />
                </div>
                <span>Questions?</span>
              </div>
            </div>
          </li>
          <li>
            <div className='nav-item-wrap' onClick={handleAddIcon}>
              <div className='nav-wrap start-battle-container add active'>
                <div className='sidebar-nav-icon'><AddIcon /> </div>
                <span>Start Battle</span>
              </div>
            </div>
          </li>
        </ul>
      </div>

      {showModal && <StartBattleModal showModal={showModal} handleClose={handleClose} setShowModal={setShowModal} setShowOpenBattleModal={setShowOpenBattleModal} setTrackInfo={setTrackInfo} setBattleOption={setBattleOption} setAddToPlaylist={setAddToPlaylist} addToPlaylist={addToPlaylist} />}
      {showOpenBattleModal && <OpenBattleModal showOpenBattleModal={showOpenBattleModal} trackInfo={trackInfo} setShowModal={setShowModal} setShowOpenBattleModal={setShowOpenBattleModal} battleOption={battleOption} getBattleList={getBattleList} handleOpenBattleClose={handleOpenBattleClose} addToPlaylist={addToPlaylist} />}
      {customModal && <CustomTextModal showModal={customModal} handleClose={handleCustomClose} title={modalTitle} body={modalBody} />}
      {showLogin && <Login showLogin={showLogin} handleLoginClose={handleLoginClose} setShowLogin={setShowLogin} setShowModel={setShowOtpModel} setEmailLinkModal={setEmailLinkModal} setShowSignUp={setShowSignUp} setUser={setUser} setShowSetUp={setShowSetUp} setId={setUserId} setToken2={setToken2} />}
      {showSignUp && <SignUp showSignUp={showSignUp} handleSignUpClose={handleSignUpClose} setShowSignUp={setShowSignUp} setShowLogin={setShowLogin} setShowModel={setShowOtpModel} setUser={setUser} user={user} setId={setUserId} setToken2={setToken2} />}
      {showOtpModal && <OtpModal showModal={showOtpModal} handleClose={handleOtpClose} setShowModel={setShowOtpModel} user={user} setShowLogin={setShowLogin} setShowSetUp={setShowSetUp} id={UserId} token2={token2} />}
      {emailLinkModal && <EmailLinkForPassword emailLinkModal={emailLinkModal} handleEmailLinkClose={handleEmailLinkClose} setShowLogin={setShowLogin} />}
      {showSetUp && <SetUpAccount showSetUp={showSetUp} handleSetUpClose={handleSetUpClose} />}
      {showAddTrack && <AddNewTrackModal showAddTrack={showAddTrack} handleAddTrackClose={handleAddTrackClose} getMyTrack={getMyTrack} />}
    </div>


  );
};

export default Sidebar;