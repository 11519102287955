import React, { useEffect, useState } from "react";
import Header from "../../Component/Header/Header";
import Sidebar from "../../Component/Sidebar/Sidebar";

import ContactUsForm from "./Components/ContactUsForm";

import './index.css'

import { updateDocumentTitle, scrollToTop } from '../../Utils';


const ContactUs = () => {


    useEffect(() => {
        scrollToTop();
        updateDocumentTitle('Contact | CNTNDR');
        return () => {
            updateDocumentTitle('');
        }
    }, []);



    return <>
        <Header />
        <Sidebar />

        <div className='contact-wrapper-main'>
            <div className='contact-banner-wrapper'>
                {/* <img src={genreData?.bannerImage ? genreData?.bannerImage : GenreBannerImg} alt='' /> */}
                <div className='contact-banner'>
                    <div className='contact-banner-content'>
                        <div className='contact-banner-title'>Contact Us</div>
                        <div className='contact-banner-sub-title'>Feel free to reach out. We're here to help.</div>
                    </div>
                </div>
            </div>
            <div className="contact-page-container">
                <div className="contact-page-text-container" >
                    <div>
                        <ContactUsForm />
                    </div>
                </div>
            </div>



        </div>

    </>

};


export default ContactUs;