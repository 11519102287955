import React from 'react'
import PhoneImg from '../../assets/images/phone.png'
import './OpenChat.scss'
// import { Button } from 'react-bootstrap'
// import { DiscordIcon } from '../Icons'
const Chat = () => {
    return (
        <>
            <div className='chat-main-wrapper'>
                <div className='main-wrapper'>
                    <div className='main-container chat-wrap'>
                        <div className='chat-container'>
                            <div className='phone-wrap'>
                                <img src={PhoneImg} alt='' />
                            </div>
                            <div className='chat-content-wrap'>
                                <div className='chat-title'>LEVEL UP WITH REWARDS</div>
                                <div className='chat-description'>
                                    <p>Whether you’re viewing your Clout balance, adding to your badge collection, or unlocking new levels, your Wallet is where you keep track of your achievements and fuel your rise to the top. </p>
                                    <p>With each badge comes new opportunities. As you collect new badges, you also earn Clout. Use Clout to enter more battles and prove you have what it takes to be a top contender.</p>
                                </div>
                                {/* <div className='chat-btn-wrap'>
                                    <Button variant='primary'><DiscordIcon /> Open Chat</Button>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Chat