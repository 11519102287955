import React, { useEffect, useState } from "react";
import Header from "../../Component/Header/Header";
import Sidebar from "../../Component/Sidebar/Sidebar";

// import ContactUs from "./Components/ContactUs";

import ArrowDownIcon from "./assets/arrow-down.svg";
import './index.css'

import { updateDocumentTitle, scrollToTop } from '../../Utils';

import { QuestionRow1, QuestionRow2 } from "./constants";



const FaqPage = () => {
    // const [activeLegalSection, setActiveLegalSection] = useState('terms_conditions');

    // const handleLegalSection = (section) => {
    //     setActiveLegalSection(section);
    // };

    const [openedQuestions, setOpenedQuestions] = useState({});

    useEffect(() => {
        scrollToTop();

        updateDocumentTitle('Support | CNTNDR');
        return () => {
            updateDocumentTitle('');
        }
    }, []);

    const handleAccordionChange = id => () => {
        setOpenedQuestions(prevState => {
            return {
                ...prevState,
                [id]: !prevState[id]
            }
        })
    }

    console.log('openedQuestions', openedQuestions)
    //  ${openedQuestions[item.id] && 'faq-question-opened'}

    return <>
        <Header />
        <Sidebar />

        <div className='faq-wrapper-main'>
            <div className='faq-banner-wrapper'>
                {/* <img src={genreData?.bannerImage ? genreData?.bannerImage : GenreBannerImg} alt='' /> */}
                <div className='faq-banner'>
                    <div className='faq-banner-content'>
                        <div className='faq-banner-title'>FAQ & Support</div>

                    </div>
                </div>
            </div>
            <div className="faq-page-container">
                <div className="faq-page-text-container" >


                    <div className="faq-page-row">
                        <div className="faq-page-column">
                            {QuestionRow1.map(item => {
                                return <div onClick={handleAccordionChange(item.id)}
                                    class={`faq-question-container ${openedQuestions[item.id] && 'faq-question-opened'}`}
                                    style={{ color: "white" }}>
                                    <div className="faq-question-header">
                                        <div>{item.question}</div>
                                        <div>
                                            <img src={ArrowDownIcon} className="img-dropdown-icon" alt='down-arrow' />
                                        </div>
                                    </div>
                                    {openedQuestions[item.id] && <div className="faq-question-description">
                                        <div>
                                            {item.answer}
                                        </div>
                                    </div>}
                                </div>
                            })}

                        </div>
                        <div className="faq-page-column">
                            {QuestionRow2.map(item => {
                                return <div onClick={handleAccordionChange(item.id)}
                                    class={`faq-question-container ${openedQuestions[item.id] && 'faq-question-opened'}`}
                                    style={{ color: "white" }}>
                                    <div className="faq-question-header">
                                        <div>{item.question}</div>
                                        <div>
                                            <img src={ArrowDownIcon} className="img-dropdown-icon" alt='down-arrow' />
                                        </div>
                                    </div>
                                    {openedQuestions[item.id] && <div className="faq-question-description">
                                        <div>

                                            {item.answer.split('\n').map(str => <div>{str}</div>)}
                                        </div>
                                    </div>}
                                </div>
                            })}
                        </div>
                    </div>
                    {/* 
                    <div>
                        <ContactUs />
                    </div> */}
                </div>
            </div>



        </div>

    </>

};


export default FaqPage;