import React, { useContext, useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useForm, Controller } from 'react-hook-form';
import { loginSchema } from '../../ValidationSchema/validationSchema';
import { yupResolver } from '@hookform/resolvers/yup';
// import axios from 'axios';
import './Login.scss'
import { Link, Navigate, useNavigate } from 'react-router-dom';
import * as cs from '../../Api/cs'
// import { toast, ToastContainer } from 'react-toastify';
import { EyeIcon, EyeIconOff, FacebookIcon, GoogleIcon } from '../../Component/Icons';
// import { auth, provider } from '../../Helper/config';
// import { signInWithPopup } from 'firebase/auth'
import { useDispatch, useSelector } from 'react-redux';
import { updateLoginStatus, updateUserData, updateUserId } from '../../Redux/Slices/user.slice';
// import OtpModal from '../../Modal/OtpModal';
import { SocketContext } from '../../Context/socket';
import Modal from 'react-bootstrap/Modal';
import { BallTriangle } from 'react-loader-spinner';
// import EmailLinkForPassword from '../EmailLinkForPassword/EmailLinkForPassword';
import { googleLogin, facebookLogin } from '../../Helper/SocialLogin.js';
import Cookies from 'js-cookie';

import { AnalyticsSendEvent } from '../../Helper/Analytics/index.js';

const Login = ({ showLogin, handleLoginClose, setShowLogin, setShowModel, setEmailLinkModal, setShowSignUp, setUser, setShowSetUp, setId, setToken2 }) => {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [rememberMe, setRememberMe] = useState(false);
    const [invalid, setInvalid] = useState(false);
    const [loading, setLoading] = useState(false);
    const [rememberId, setRememberId] = useState(null);
    const socket = useContext(SocketContext)
    const { userId } = useSelector(state => state.user)


    const dispatch = useDispatch();

    const isValidEmail = (email) => {
        // Regular expression for checking email format
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };




    const {
        register,
        handleSubmit,
        getValues,
        formState: { errors },
        setValue,
        clearErrors,
    } = useForm({
        resolver: yupResolver(loginSchema),
        mode: 'onChange',
    });;



    const onSubmit = async (data) => {
        setLoading(true);
        setUser(data)
        let userData;
        userData = {
            emailOrUsername: data?.email,
            password: data?.password,

        }
        const response = await cs.post('login', 'users', userData, null)

        if (response?.data?.code === 200) {
            console.log('responseeeeeeeeeee', response)
            AnalyticsSendEvent({
                category: "Auth",
                action: "signin",
                label: "Sign In",
                data: {
                    userId: response?.data?.data?.userId,
                    email: response?.data?.data?.email
                }
            });

            // socket.emit("updateSocketId", data)
            localStorage.setItem('token', response?.data?.data?.token)
            dispatch(updateLoginStatus(true))
            dispatch(updateUserId(response?.data?.data?.userId))
            handleLiveStatus(response?.data?.data?.userId)
            // toast.success("Login successful!");
            dispatch(updateUserData(response?.data?.data?.user))
            // socketConnection(response?.data?.data?.userId)
            setLoading(false);
            handleLoginClose()
            navigate('/')


        }
        else if (response?.data?.code === 200) {

            localStorage.setItem('token', response?.data?.data?.token)
            dispatch(updateLoginStatus(true))
            dispatch(updateUserId(response?.data?.data?.userId))
            // toast.success("Login successful!");
            let userId = response?.data?.data?.userId;
            handleLiveStatus(userId)
            setLoading(false);
            // socketConnection(response?.data?.data?.userId)
            handleLoginClose()
            dispatch(updateUserData(response?.data?.data?.user))
            navigate('/')

        }
        else if (response?.data?.is_active === false) {
            handleLoginClose()
            setToken2(response?.data?.token?.token)
            setLoading(false);
            setId(response?.data?.token?.userId)
            setShowModel(true)
        }
        else if (response?.data?.userName == false) {
            handleLoginClose()
            localStorage.setItem('token', response?.data?.token?.token)
            dispatch(updateLoginStatus(true))
            dispatch(updateUserId(response?.data?.token?.userId))
            // socketConnection(response?.data?.token?.userId)
            setLoading(false);
            setShowSetUp(true)
        }
        else {
            // toast.error("Invalid Credentials!");
            // handleLoginClose()
            setLoading(false);
            setInvalid(true)
        }


    };
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    // const handleRememberMeChange = () => {
    //     setRememberMe((prevRememberMe) => !prevRememberMe);
    // };

    // useEffect(() => {

    // }, [third])
    useEffect(() => {
        const fetchRememberId = async () => {
            try {
                const rememberId = Cookies.get('rememberId');
                if (rememberId) {
                    setRememberMe(true)
                    setRememberId(rememberId);
                }
                const response = await cs.get(`getremember/${rememberId}`, 'users', {}, null);
                if (response) {
                    const email = response?.data?.data?.email
                    const password = response?.data?.data?.password
                    setValue('email', email)
                    setValue('password', password)
                }
            } catch (error) {
                console.error("Error fetching rememberId", error);
            }
        };

        fetchRememberId();
    }, []);

    const handleRememberMeChange = async (e) => {
        setRememberMe(e.target.checked);
        if (e.target.checked) {
            const email = getValues('email');
            const password = getValues('password');
            const userData = { email, password };
            const response = await cs.post('rememberMe', 'users', userData, null);
            if (response) {
                Cookies.set('rememberId', response?.data.data?._id, { expires: 365 });
            }
        }
    };


    // Handle Live Status ...
    const handleLiveStatus = (userId) => {
        let data = {
            id: userId,
            status: true,
        }

        socket.emit('live', data);
    }






    const handleGoodleLogin = async () => {

        const response = await googleLogin()
        dispatch(updateLoginStatus(true))
        dispatch(updateUserId(response?.user?._id))
        // socketConnection(response?.user?._id)
        // toast.success("Login successful!");
        handleLiveStatus(response?.user?._id)
        localStorage.setItem("token", response?.token)
        handleLoginClose()
        dispatch(updateUserData(response?.user))
        if (response?.user?.userName == null) {

            setShowSetUp(true)
        }

        navigate('/')

    }

    const handleFacebookLogin = async () => {
        const response = await facebookLogin()

        dispatch(updateLoginStatus(true))
        localStorage.setItem("token", response?.token)
        dispatch(updateUserId(response?.user?._id))
        // socketConnection(response?.user?._id)
        handleLiveStatus(response?.user?._id)
        handleLoginClose()
        dispatch(updateUserData(response?.user))
        if (response?.user?.userName == null) {
            setShowSetUp(true)
        }
        navigate('/')
    }


    const handleEmailLinkModal = () => {
        setShowLogin(false)
        setEmailLinkModal(true)
    }
    const handleSignUpModal = () => {
        setShowLogin(false)
        setShowSignUp(true)
    }
    return (
        <>
            <Modal show={showLogin} onHide={handleLoginClose} aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>

                </Modal.Header>
                <Modal.Body>
                    {loading && ( // Show loader if loading state is true
                        <div className="loader-container">
                            <div className="loader">
                                <BallTriangle
                                    type="TailSpin"
                                    color="#00BFFF"
                                    height={50}
                                    width={50}
                                />
                            </div>
                        </div>
                    )}
                    <div className='auth-form-wrapper-main'>
                        <form className="auth-form-wrapper" onSubmit={handleSubmit(onSubmit)}>
                            {invalid && <div className="form-action-row" style={{ color: "red", textAlign: "center" }}>Invalid Credentials</div>}
                            <div className='auth-form-header'>
                                <div className='auth-form-title'>Login </div>
                                <div className='auth-form-link' onClick={handleSignUpModal}> Not a member yet ?</div>
                            </div>
                            <div className="social-btn-wrap">
                                <Button variant="outline-secondary" type="button" onClick={handleGoodleLogin}><GoogleIcon />Login with Google</Button>
                            </div>
                            <div className="social-btn-wrap">
                                <Button variant="outline-secondary" type='button' onClick={handleFacebookLogin}><FacebookIcon /> Login with Facebook</Button>
                            </div>
                            <div className='d-flex  w-100 justify-content-center text-white'>Or</div>
                            <div className='auth-form'>
                                <div className="form-row">

                                    <Form.Control type="text" name="email" placeholder='E-mail or Username'  {...register("email")}
                                        {...register('email')}
                                        onChange={(e) => {
                                            setValue('email', e.target.value, { shouldValidate: true });
                                        }}
                                    />
                                    {errors.email && <div className="errorMsg">{errors.email.message}</div>}
                                </div>
                                <div className="form-row have-icon">

                                    <Form.Control placeholder='Password' type={showPassword ? 'text' : 'password'} name="password" {...register("password")}

                                        onChange={(e) => {
                                            setValue('password', e.target.value, { shouldValidate: true });
                                        }}
                                    />
                                    <div className='icon-wrap' type="button" onClick={togglePasswordVisibility}>
                                        {showPassword ? <EyeIconOff /> : <EyeIcon />}
                                    </div>
                                    {errors.password && <div className="errorMsg">{errors.password.message}</div>}
                                </div>
                                <div className='forgot-password d-flex justify-content-end' >
                                    <span onClick={handleEmailLinkModal}>Forgot Password?</span>
                                </div>
                                <div className="form-action-row">
                                    <div className='remember-wrap flex-grow-1'>
                                        <Form.Check
                                            type='checkbox'
                                            id='rememberMe'
                                            {...register('rememberMe')}
                                            checked={rememberMe}
                                            onChange={handleRememberMeChange}
                                            label='Remember Me'
                                        />

                                    </div>
                                    <div className='flex-shrink-0 btn-wrap'> <Button variant='primary' type="submit">Login</Button></div>
                                </div>



                            </div>
                        </form>

                        {/* {showModal && <OtpModal showModal={showModal} handleClose={handleClose} setShowModel={setShowModel} user={user} setShowLogin={setShowLogin} />}
                        {emailLinkModal && <EmailLinkForPassword emailLinkModal={emailLinkModal} handleEmailLinkClose={handleEmailLinkClose} setShowLogin={setShowLogin} />} */}
                    </div>
                </Modal.Body>

            </Modal>

        </>
    )
}

export default Login
