import ReactGA from 'react-ga4';

const { initialize, event, send, set } = ReactGA;

export const InitializeAnalytics = () => {
    initialize('G-J6ZER59KJG');
};

export const AnalyticsSendView = ({
    hitType,
    page,
    title
}) => {
    send({
        hitType,
        page,
        title
    });
};

export const AnalyticsSendEvent = ({
    category,
    action,
    label,
    noInteraction,
    transport,
    data = {}
}) => {
    console.log('Analytics Send Event', {
        category,
        action,
        label,
        noInteraction,
        transport,
        ...data
    })
    event(action, {
        category,
        action,
        label,
        noInteraction,
        transport,
        ...data
    });
    // event(category)

};


export const AnalyticsSetUser = ({
    userId
}) => {
    set({
        userId
    });
};