const DEFAULT_WINDOW_TITLE = 'CNTNDR | Beat Battles & Music Competitions | Showcase Your Talent';
export const updateDocumentTitle = (title = '') => {
    window.document.title = title || DEFAULT_WINDOW_TITLE;
}


const DEFAULT_METATAGS = {
    'og:title': 'CNTNDR | Beat Battles & Music Competitions | Showcase Your Talent',
    'description': 'CNTNDR is the top music competition platform for beat makers and producers. Compete in beat battles, earn badges, climb the leaderboard, and showcase your talent. Join the community.',
    'twitter:title': 'CNTNDR | Beat Battles & Music Competitions | Showcase Your Talent'
}

export const updateMetaTags = (data) => {
    for (let index = 0; index < data.length; index++) {
        if (data[index].name && DEFAULT_METATAGS[data[index].name]) {
            const value = data[index].value ? data[index].value : DEFAULT_METATAGS[data[index].name]
            window.document.querySelector(`meta[name="${data[index].name}"]`).setAttribute("content", value);
        }
    }
}
export const capitalizeFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: "instant",
    });
}