import React, { useEffect, useState } from 'react'
import './StartBattleModal.scss'
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { trackSchema } from '../../ValidationSchema/validationSchema';
import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import * as cs from '../../Api/cs'
import useHeader from '../../Helper/useHeader';
import Form from 'react-bootstrap/Form';
import { CloseIcon, DeleteIcon, ImageIcon, TrackIcon } from '../../Component/Icons';
import UserImg from '../../assets/images/thumbnail.png'
import { useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';

const StartBattleModal = ({ showModal, handleClose, setShowModal, setShowOpenBattleModal, setTrackInfo, setBattleOption, setAddToPlaylist, addToPlaylist, genre = null }) => {

  const headers = useHeader()
  const [trackList, setTrackList] = useState([]);
  const [genreList, setGenreList] = useState([]);
  const [genreData, setGenreData] = useState({})
  const [image, setImage] = useState(null)
  const [selectedFile, setSelectedFile] = useState(null);
  const [trackDuration, setTrackDuration] = useState(0)
  // const [addToPlaylist, setAddToPlaylist] = useState(false);

  const { userId } = useSelector(state => state.user)

  const { control, handleSubmit, setValue, formState: { errors }, register, watch, useWatch, reset, clearErrors } = useForm({
    resolver: yupResolver(trackSchema),
    mode: 'onChange',
  });;

  useEffect(() => {
    if (genre == null) {
      getTrackList()
      getGenreList()
    }
    else if (genre != null) {
      getGenreDetail()
      getTrackListOfGenre()
    }

    setAddToPlaylist(false)
  }, [])


  const getTrackList = async () => {
    const res = await cs.get('createdBy/' + userId, 'track', {}, headers)
    setTrackList(res?.data?.data)
  }

  const getGenreDetail = async (req, res) => {
    const response = await cs.get('getGenreDetail/' + genre, 'genre', {}, headers)
    // setGenreData(response?.data?.data)
    setGenreData(response?.data?.data)
    setValue('genreSelect', response?.data?.data?._id);
    setGenreList([])
  }

  const getTrackListOfGenre = async () => {
    const response = await cs.get('getUserTrackOfGenre/' + userId + '/' + genre, 'track', {}, headers)
    setTrackList(response?.data?.data)
  }

  const getGenreList = async () => {
    const res = await cs.get('list', 'genre', null, headers)
    setGenreList(res?.data?.data)
  }

  const onSubmit = (data) => {

    if (data?.battleOption === 'chooseFromMyTrack') {
      setBattleOption('chooseFromMyTrack')
      const selectedTrack = trackList?.find((track) => track?._id === data?.selectedTrack);
      setTrackInfo(selectedTrack);

    }
    else if (data?.battleOption === 'addNewTrack') {
      setBattleOption('addNewTrack')

      const track_data = {
        'track_video': data?.fileInput[0],
        'track_image': data?.imageInput[0],
        'track_name': data?.trackNameInput,
        'track_genre': data?.genreSelect,
        'duration': trackDuration && trackDuration
      }


      setTrackInfo(track_data);


    }

    setShowModal(false);
    setShowOpenBattleModal(true);
  };


  useEffect(() => {
    if (!showModal) {
      reset();
      setImage(null);
      setSelectedFile(null);
    }
  }, [showModal, reset]);


  const getTrackDuration = (file) => {
    const audio = new Audio(URL.createObjectURL(file));
    audio.addEventListener('loadedmetadata', () => {
      const durationInSeconds = audio.duration;
      const minutes = Math.floor(durationInSeconds / 60);
      const seconds = Math.floor(durationInSeconds % 60);
      const formattedDuration = `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
      setTrackDuration(formattedDuration);


      setTrackDuration(formattedDuration);
    });
  };


  const handleFileSelect = (e) => {
    setValue('fileInput', e.target.files);
    setSelectedFile(e.target.files[0]);
    clearErrors('fileInput');
    getTrackDuration(e.target.files[0]); // Get duration when file is selected
  };

  const handleImageSelect = (acceptedFiles) => {
    setValue('imageInput', acceptedFiles);
    setImage(URL.createObjectURL(acceptedFiles[0]));
    clearErrors('imageInput');
  };

  const handleAudioSelect = (acceptedFiles) => {
    setValue('fileInput', acceptedFiles);
    setSelectedFile(acceptedFiles[0]);
    clearErrors('fileInput');
    getTrackDuration(acceptedFiles[0]);
  };

  const { getRootProps: getRootPropsForImage, getInputProps: getInputPropsForImage } = useDropzone({
    // accept: 'image/*',
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg']
    },
    maxFiles: 1,
    multiple: false,
    onDrop: handleImageSelect,
  });

  const { getRootProps: getRootPropsForAudio, getInputProps: getInputPropsForAudio } = useDropzone({
    accept: {
      'audio/mpeg': ['.mp3'],
    },
    maxFiles: 1,
    multiple: false,
    onDrop: handleAudioSelect,
  });

  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Start A Battle</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="w-100">
          <form className='battle-form' onSubmit={handleSubmit(onSubmit)}>
            <div className={`choose-track-wrap ${watch('battleOption') === 'chooseFromMyTrack' ? 'active' : ''}`}>
              <Form.Check
                type="radio"
                id="chooseFromMyTrack"
                name="battleOption"
                value="chooseFromMyTrack"
                {...register('battleOption')}
                label='Choose from my Library'
              />


              {watch('battleOption') === 'chooseFromMyTrack' && (
                <>

                  <Controller
                    name="selectedTrack"
                    control={control}
                    render={({ field }) => (
                      <Form.Select  {...field}>
                        {trackList && trackList?.length > 0 ? <option value="">Select Track</option> : <option value="">No Track Available</option>}

                        {trackList?.map((track) => (
                          <option key={track?._id} value={track?._id}>
                            {track?.track_name}
                          </option>
                        ))}
                      </Form.Select>
                    )}
                  />
                  {errors?.selectedTrack && <p className="errorMsg">{errors?.selectedTrack?.message}</p>}

                </>
              )}

            </div>
            <div className={`add-new-track ${watch('battleOption') === 'addNewTrack' ? 'active' : ''}`}>
              <Form.Check
                type="radio"
                id="addNewTrack"
                name="battleOption"
                value="addNewTrack"
                {...register('battleOption')}
                label='Add a new track'
              />


              {watch('battleOption') === 'addNewTrack' && (
                <>
                  <Form.Group className='file-upload-image  form-row' >
                    {!image &&
                      <label htmlFor="imageInput" {...getRootPropsForImage()} className='file-upload'>
                        <ImageIcon />
                        <input {...getInputPropsForImage()} accept="image/*" />
                        {/* <input type="file"
                          id="imageInput"
                          accept="image/*"
                          onChange={(e) => {
                            setValue('imageInput', e.target.files);
                            setImage(URL.createObjectURL(e.target.files[0]));
                            clearErrors('imageInput');
                          }} /> */}
                        <div className='file-upload-text'>
                          Choose image<br />
                          or drop it here
                        </div>
                      </label>

                    }
                    {/*Image upload functionality done here */}

                    {image &&
                      <div className='image-upload-holder'>
                        <img src={image} alt='user' />
                        <div className='delete-img-wrap' onClick={() => setImage(null)}>
                          <DeleteIcon />
                        </div>
                      </div>
                    }

                    {errors.imageInput && <div className="errorMsg">{errors.imageInput.message}</div>}
                  </Form.Group>

                  <Form.Group className='form-row file-upload-audio'>


                    {!selectedFile &&
                      <> <label htmlFor="fileInput" {...getRootPropsForAudio()} className='file-upload'>
                        {/* <input type="file" id="fileInput" accept=".mp3,.mp4" onChange= {handleFileSelect } /> */}
                        <input {...getInputPropsForAudio()} />

                        <div className='icon-wrap'><TrackIcon /></div>
                        <div className='file-upload-text'>Choose track or <br />
                          drop it here <br />
                          (in .mp3 format)</div>
                      </label>
                      </>
                    }
                    {/*Remove Audion track name */}
                    {selectedFile && <>
                      <label >
                        <div className='icon-wrap'><TrackIcon /></div>
                        <div className='file-upload-text'>{selectedFile.name}
                        </div>
                        <div className='close-wrap' onClick={() => setSelectedFile(null)}>
                          <CloseIcon />
                        </div>
                      </label>
                    </>}



                    {errors.fileInput && <div className="errorMsg">{errors.fileInput.message}</div>}
                  </Form.Group>

                  <Form.Group className='form-row' >
                    <Form.Control type="text" id="trackNameInput" {...register('trackNameInput')} placeholder='Track Name' />
                    {errors.trackNameInput && <div className="errorMsg">{errors.trackNameInput.message}</div>}
                  </Form.Group>

                  {genre ? <Form.Group className='form-row' >
                    <Form.Control type="text" id="genreSelect" value={genreData.label} readOnly />
                    {errors.genreSelect && <div className="errorMsg">{errors.genreSelect.message}</div>}
                  </Form.Group>
                    :
                    <Form.Group className='form-row'>
                      <Form.Select id="genreSelect" {...register('genreSelect')}>
                        <option value="">Select Genre</option>
                        {genreList?.map((genre) => (
                          <option key={genre?._id} value={genre?._id}>
                            {genre?.label}
                          </option>
                        ))}
                      </Form.Select>
                      {errors.genreSelect && <div className="errorMsg">{errors.genreSelect.message}</div>}
                    </Form.Group>
                  }



                  <Form.Group className='form-row'>
                    <Form.Check
                      type="checkbox"
                      id="addToPlaylist"
                      label="Add To My Library"
                      checked={addToPlaylist}
                      onChange={(e) => setAddToPlaylist(e.target.checked)}
                    />
                  </Form.Group>
                </>
              )}



            </div>
            {errors.battleOption && <p className="errorMsg">{errors.battleOption.message}</p>}

            <div className='battle-btn-wrap'>
              <Button variant="primary" type="submit">
                Next
              </Button>
            </div>
          </form>
        </div>
      </Modal.Body>

    </Modal>
  )
}

export default StartBattleModal
