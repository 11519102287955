import React, { useEffect, useRef, useState } from 'react'
import { CloutIcon, ImageIcon, PreviousArrow } from '../Icons'
import Scrollbars from 'react-custom-scrollbars-2'
import './Badges.scss'
import { ProgressBar } from 'react-bootstrap'
import useHeader from '../../Helper/useHeader'
import * as cs from '../../Api/cs'
import { useDispatch, useSelector } from 'react-redux'
import { updateBadgeId, updateIsBadgeInfo, updateOpenDirectly } from '../../Redux/Slices/badge.slice'
import { updateWalletShow } from '../../Redux/Slices/modal.slice'

const Badges = ({ setBadgeShow }) => {

    const headers = useHeader()
    // const [isBadgeInfo, setIsBadgeInfo] = useState(false);
    const [badgeList, setBadgeList] = useState([])
    const [earnBadge, setEarnBadge] = useState([])
    const [badgeDetail, setBadgeDetail] = useState({})
    const [isWindow, setIsWindow] = useState(false);
    const { isBadgeInfo, badgeId, openDirectly } = useSelector(state => state.badge)
    const dispatch = useDispatch()
    const { userId } = useSelector(state => state.user)
    const [commonBadges, setCommonBadges] = useState([]);
    const walletRef = useRef(null);

    useEffect(() => {

        const common = badgeList.filter(badge =>
            earnBadge.some(earned => earned.badgeId._id === badge._id)
        );
        setCommonBadges(common);
    }, [badgeList, earnBadge]);


    useEffect(() => {
        getAllBadges()

    }, [])

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 1200) {
                setIsWindow(true);
            } else {
                setIsWindow(false);
            }
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (isWindow) {
            document.body.classList.add('hidden');
        } else {
            document.body.classList.remove('hidden');
        }
    }, [isWindow]);

    useEffect(() => {
        document.body.classList.toggle('hidden', isWindow);
        const handleClickOutside = (event) => {
            if (walletRef.current && !walletRef.current.contains(event.target)) {
                setBadgeShow(false)
                dispatch(updateIsBadgeInfo(false))
                dispatch(updateOpenDirectly(true))

            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        getBadgeInfo()
    }, [badgeId])


    useEffect(() => {
        getUserEarnedBagdes()
    }, [])



    const getAllBadges = async () => {
        const response = await cs.get('getAllBadges', 'badge', null, headers)
        setBadgeList(response?.data?.data)
    }

    const getBadgeInfo = async () => {
        const response = await cs.get('getBadgeDetails/' + badgeId, 'badge', {}, headers)
        setBadgeDetail(response?.data?.data)
    }
    const handleBadgeInfo = (badgeId) => {
        dispatch(updateBadgeId(badgeId))
        dispatch(updateIsBadgeInfo(true))

    }
    const getUserEarnedBagdes = async () => {
        const response = await cs.get('getEarnedBadges/' + userId, 'badge', {}, headers)
        setEarnBadge(response?.data?.data)
    }


    const handlePreviousArrow = () => {

        if (isBadgeInfo && !openDirectly) {
            dispatch(updateIsBadgeInfo(false))

        }
        else if (isBadgeInfo && openDirectly) {
            dispatch(updateIsBadgeInfo(false))
            setBadgeShow(false)
            dispatch(updateWalletShow(true))
            dispatch(updateOpenDirectly(false))
        }
        else if (!isBadgeInfo) {
            setBadgeShow(false)
            dispatch(updateWalletShow(true))
            document.body.classList.remove('hidden');
        }
    }

    const calculateProgressV2 = (currentLevel, totalLevels) => {
        return (currentLevel / totalLevels) * 100;
    };

    const calculateProgress = (currentQuantity, currentLevelQuantity, nextLevelQuantity) => {
        const levelRange = nextLevelQuantity - currentLevelQuantity;
        const progress = ((currentQuantity - currentLevelQuantity) / levelRange) * 100;
        console.log('calculateProgress22 progress', progress)
        console.log('calculateProgress22 currentQuantity', currentQuantity)
        console.log('calculateProgress22 currentLevelQuantity', currentLevelQuantity)
        console.log('calculateProgress22 nextLevelQuantity', nextLevelQuantity)
        console.log('calculateProgress22 levelRange', levelRange)
        return progress;
    };
    const isCommonBadge = (badgeId) => {
        return commonBadges.some(badge => badge._id === badgeId);
    };
    const isCommonBadgeImage = (badgeImage) => {
        return commonBadges.some(badge => badge.badgeImage === badgeImage);
    };

    const userLevel = badgeDetail?.userLevel
    const levelToShow = badgeDetail?.badge?.levels.find(level => level.levelNumber === userLevel);
    // progress bar reset start 
    const nextLevel = badgeDetail?.badge?.levels[userLevel + 1] ?? {};
    const currentQuantity = badgeDetail?.userQuantity ?? 0;
    const currentLevelQuantity = levelToShow?.quantity ?? 0;
    const nextLevelQuantity = nextLevel?.quantity ?? currentLevelQuantity;
    // progress bar reset end 
    const commonBadgess = badgeList.filter(item => isCommonBadge(item._id)); // color badges
    const nonCommonBadgess = badgeList.filter(item => !isCommonBadge(item._id)); // grayscaled badges
    const sortedBadgeList = [...commonBadgess, ...nonCommonBadgess]; //  it sort the badges in a sequence
    return (
        <>
            <div className='badge-wrapper' ref={walletRef}>
                <div className='badge-header'>
                    <div className='back-btn' onClick={handlePreviousArrow}><PreviousArrow /></div>
                    <div className='badge-title'>Badges</div>
                </div>
                <div className='badge-content-wrapper'>
                    <Scrollbars className='badge-scroll-wrapper' style={{ width: '100%', height: '100%' }}>
                        {!isBadgeInfo ?
                            <>
                                <div className='badge-list-wrapper'>
                                    {sortedBadgeList.length > 0 && sortedBadgeList.map((item) => (
                                        <div
                                            key={item._id}
                                            className={`badge-box ${isCommonBadge(item._id) ? '' : 'grayscale'}`}
                                            onClick={() => { handleBadgeInfo(item?._id) }}
                                        >
                                            <div className='bagde-img-wrap'>
                                                <img src={item?.badgeImage} alt="badges" />
                                            </div>
                                            <div className='badge-title'>{item?.badgeName}</div>
                                        </div>
                                    ))}
                                </div>
                            </>
                            :
                            <>
                                <div className='badge-detail-container'>
                                    <div className='badge-detail-wrap'>
                                        <div className='badge-img-wrap'>
                                            {/* individual badges image  */}
                                            <img
                                                src={badgeDetail?.userLevel === 0 || badgeDetail?.userLevel === null
                                                    ? badgeDetail?.badge?.levels[0]?.levelImage
                                                    : levelToShow?.levelImage}
                                                alt={`Level ${badgeDetail?.userLevel} Badge`}
                                                className={isCommonBadgeImage(badgeDetail?.badge?.badgeImage) ? '' : 'grayscale'}
                                            />
                                        </div>
                                        <div className='badge-name'>{badgeDetail?.badge?.badgeName}
                                            <div className='rewards-wrap'>
                                                <div className='reward-title'>Reward Earned</div>
                                                <div className='clout-wrapper'>
                                                    <div className='clout-icon-wrap'><CloutIcon /></div>
                                                    <div className='clout-earn'>
                                                        {badgeDetail?.badge?.levels
                                                            .slice(0, badgeDetail?.userLevel) // Get levels up to and including the user level
                                                            .reduce((total, level) => total + level.rewards, 0) // Sum the rewards
                                                            || 0} Clout
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    {badgeDetail?.badge?.totalLevels > 1 &&
                                        <div className='badge-level-wrap'>
                                            <div className='badge-level-title'>Badge Level</div>
                                            <div className='badge-progress-wrap'>
                                                <div className='badge-level'>
                                                    <div className='badge-img-wrap'>
                                                        {userLevel === 0 || userLevel === null ? (
                                                            <div className='pink-fill'></div>
                                                        ) : (
                                                            levelToShow?.levelImage ? (
                                                                <img src={levelToShow?.levelImage} alt={`Level ${userLevel} Badge`} />
                                                            ) : (
                                                                <ImageIcon /> // Provide an alternative icon if levelImage is not available
                                                            )
                                                        )}
                                                    </div>

                                                    {userLevel !== 0 && userLevel !== null && (
                                                        <div className='level-name'>Level {badgeDetail?.userLevel !== null ? badgeDetail?.userLevel : 0}</div>
                                                    )}
                                                </div>

                                                <div className='badge-progress'>
                                                    {/* <ProgressBar variant='primary'
                                                        now={calculateProgress(currentQuantity, currentLevelQuantity, nextLevelQuantity)}
                                                    /> */}

                                                    <ProgressBar variant='primary' now={badgeDetail?.badge?.totalLevels === 1
                                                        ? 100
                                                        : calculateProgressV2(
                                                            badgeDetail?.userQuantity != null ? badgeDetail?.userQuantity : 0,
                                                            badgeDetail?.badge?.levels[badgeDetail?.userLevel != null ? badgeDetail?.userLevel : 0]?.quantity
                                                        )} />
                                                    <div className="progress-text" style={{ paddingTop: 12 }}>
                                                        {badgeDetail?.userQuantity ?? 0}/{badgeDetail?.badge?.levels[badgeDetail?.userLevel]?.quantity ?? 0}
                                                    </div>
                                                </div>
                                                {/* Next level image  */}
                                                <div className='badge-level'>
                                                    <div className='badge-img-wrap'>
                                                        {badgeDetail?.badge?.levels[badgeDetail?.userLevel + 1]?.levelImage ? (
                                                            <img src={badgeDetail?.badge?.levels[badgeDetail?.userLevel]?.levelImage} alt={`Level ${badgeDetail?.userLevel} Badge`} className='badge-image-grayscale' />
                                                        ) : (
                                                            <ImageIcon />
                                                        )}
                                                    </div>
                                                    {badgeDetail?.userLevel + 1 !== badgeDetail?.badge?.totalLevels ?
                                                        <div className='level-name'>Level {(badgeDetail?.userLevel !== null ? badgeDetail?.userLevel : 0) + 1}</div>
                                                        :
                                                        <div className='level-name'>Max</div>
                                                    }
                                                </div>
                                                {/* next level image end  */}
                                            </div>
                                        </div>
                                    }
                                    <div className='badge-info-wrap'>
                                        <div className='badge-info'>
                                            <div className='badge-info-title'>Description:</div>
                                            <div className='badge-info-description'>{badgeDetail?.badge?.description}</div>
                                        </div>
                                        <div className='badge-info'>
                                            <div className='badge-info-title'>How to Earn:</div>
                                            <div className='badge-info-description'>{badgeDetail?.badge?.howToEarn}</div>
                                        </div>
                                    </div>

                                </div>
                            </>}
                    </Scrollbars>
                </div>
            </div>
        </>
    )
}

export default Badges