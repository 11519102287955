import React from 'react';
import CookieConsent from "react-cookie-consent";

const style = {
    acceptButton: { backgroundColor: "#F2059F", color: "white", fontSize: "13px" },
    declineButton: { backgroundColor: "transparent", color: "white", fontSize: "13px", border: 'solid 0.7px white' }
}
const CustomCookieConsent = () => {
    return <CookieConsent
        enableDeclineButton={true}
        buttonStyle={style.acceptButton}
        declineButtonStyle={style.declineButton}
        declineButtonText="Learn More"
        customDeclineButtonProps={{
            onClick: () => {

                window.location.href = "/#/legal?menu=privacy_policy";
            }
        }}
    >We use cookies to provide the services and features offered on our website, and to improve our user experience.

    </CookieConsent>
};

export default CustomCookieConsent;