import React, { useEffect } from 'react'
import Sidebar from '../../Component/Sidebar/Sidebar';
import Header from '../../Component/Header/Header';
import './ActiveBattlePage.scss'
import ActiveBattleBanner from './ActiveBattleBanner/ActiveBattleBanner';
import PopularArtist from '../../Component/PopularArtist/PopularArtist';
// import OpenChat from '../../Component/OpenChat/OpenChat';
import RegistrationBottom from '../../Component/RegistrationBottom/RegistrationBottom';
import BattleSlider from './BattleSlider/BattleSlider';
import LeaderBoard from './BattleLeaderBoard/BattleLeaderBoard';
import BattlesByGenre from './BattlesByGenre/BattlesByGenre';
import NewBattles from '../../Component/Battles/NewBattles';
import EndingBattles from '../../Component/Battles/EndingBattles';
import JoinBattles from '../../Component/Battles/JoinBattles';
import TrendingBeats from '../../Component/TrendingBeats/TrendingBeats';
import { updateSearch } from '../../Redux/Slices/search.slice';
import { useDispatch, useSelector } from 'react-redux';
import RegistrationBottomUser from '../../Component/RegistrationBottomUser/RegisterBottomUser';


import { updateDocumentTitle } from '../../Utils';


const ActiveBattlePage = () => {
  const { userId } = useSelector(state => state.user);
  const { search } = useSelector(state => state.search)
  const dispatch = useDispatch()

  useEffect(() => {
    if (search) {
      dispatch(updateSearch(''))
    }
    scrollToTop();

    updateDocumentTitle(`All Beat Battles & Competitions | CNTNDR`);
    return () => {
      updateDocumentTitle('')
    }
  }, [])

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };


  return (
    <>
      <Sidebar />
      <Header />
      <div className='active-battle-wrapper'>

        <ActiveBattleBanner />
        <BattleSlider />
        <LeaderBoard />
        <BattlesByGenre />
        <div className='battles-main-wrapper'>
          <div className='main-wrapper'>
            <div className='main-container'>
              <div className='battles-wrapper'>
                <NewBattles />
                <EndingBattles />
                <JoinBattles />
              </div>
            </div>
          </div>
        </div>
        <PopularArtist />
        {/* <OpenChat /> */}
        <TrendingBeats />
        {!userId ? <RegistrationBottom /> : <RegistrationBottomUser />}
      </div>

    </>
  )
}

export default ActiveBattlePage